@import '~react-image-gallery/styles/scss/image-gallery.scss';

html {
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  font-family: 'Noto Sans Hebrew', 'Helvetica', 'Noto Sans', 'Roboto', 'Arial', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

.freeze {
  overflow: hidden;
}

.rtl {
  direction: rtl;
}

.app-wrapper {
  padding-bottom: 20px;
  max-width: 450px;
  margin: auto;
}

button.hidden {
  display: none;
}

a {
  -webkit-tap-highlight-color: transparent;
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}

a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

#agreementContent {
  line-height: 1.4 !important;

  span {
    line-height: 1.4 !important;
  }

  ol,
  ul {
    padding-right: 0 !important;
    list-style-position: inside;
  }

  ol li,
  ul li {
    margin: 0 0 2px 0 !important;
  }
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 70px !important;
}

svg.accordion_icon {
  width: 24px;
  height: 24px;
}
